import React from 'react';
import '../styles.css';

const Vision = () => {
  return (
    <div className="page-container">
      <h1>Vision</h1>
      <p>Tag: To be the destination for every house Dreamer</p>
      <p>
        By being the contractor of choice in our industry, we aim to be the top brand in construction,
        recognised for quality and innovation, while also supporting our employees and the communities in which we work.
        To be successful in business, we believe it is vital to listen to our clients' demands, keep open lines of communication,
        excel at the fundamentals, and care for our most valuable asset - our people.
        SR Constructions strives for 'future growth' through improving partnerships with existing clients, architects,
        developers, government and private organisations, and vigorously exploring new prospects around the country.
      </p>
    </div>
  );
};

export default Vision;
