import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';

import './Home.css';
import Carousel3D from './animations/Carousel3D';
import img1 from '../pages/animations/carosl/cc1.jpg';
import img2 from '../pages/animations/carosl/cc2.jpg';      
import img3 from '../pages/animations/carosl/cc3.jpg';
import img4 from '../pages/animations/carosl/cc4.jpg';
import img5 from '../pages/animations/carosl/cc5.jpg';
import logoimg from '../logo_jsr-removebg-preview.png';
import { Link } from 'react-router-dom';
import NewsScroller from './Carousel';

const CoreValues = [
  {
    title: "Integrity",
    description: "We maintain the highest standards of integrity in all our actions.",
    image: require('../images/core4.jpg'), // Example path
  },
  {
    title: "Quality",
    description: "We deliver quality work and excellent services.",
    image: require('../images/core1.webp'), // Example path
  },
  {
    title: "Innovation",
    description: "We foster innovation to stay ahead in the industry.",
    image: require('../images/core2.jpeg'), // Example path
  },
  {
    title: "Loyalty",
    description: "Building long-term relationships with clients and stakeholders based on mutual respect and dedication.",
    image: require('../images/core3.png'),
  },
];

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
];

const testimonials = [
  {
    name: "Ravisai Kotha",
    text: "Thank you JSR CONSTRUCTIONS for this beautiful house you people are supported us from beginning to end a lot of respect towards your work the you people are handling and the guidence which you give to us is extremely nice and even you reached our expectations and our thoughts🙏🙏☺️. And you completely satisfied our parents with your amazing working 😊 I hope in future we will pair once again Heart fully thanking you 🙏",
  },
  {
    name: "Sandhya Rani Duggireddy",
    text: "We recently decided to buy a house in Hyderabad  (India) and, being from the USA were looking for a reliable partner to make our vision a reality. We connected with JSR Constructions, led by Obul Reddy, and were thoroughly impressed by his professionalism, attention to detail, and dedication to client satisfaction. He guided us through every step of the process, keeping us informed and ensuring our needs were met seamlessly across borders. They delivered a beautifully crafted home on time and within budget, exceeding our expectations and making JSR Constructions a highly recommended choice for anyone looking to build in India.",
  },
  {
    name: "Sneha deekshitha Kadiri",
    text: "Jsr construtions provides high quality infrastructures with best quality materials .  They give a strong commitment  to the customer. We are satisfied with our house construction. therefore ,We highly recommend you buy their plots and houses .",
  },
  {
    name: "SHANTARAM K J",
    text: "Mr. Reddy garu, Everything would be better, if more builders like you work minded. One thing, I can say proudly  that your creative potential seems limitless and also you are a great listener... Last but not least... You are outstanding Creator and stood behind commitment that is beauty of Mr. Obul Reddy........",
  },
  {
    name: "Kumar bharat ch",
    text: "JSR builds high quality of construction and maintenance good customer relationship.It is highly recommend to buy Plots and Houses from JSR.",
  },
  // Add more testimonials as needed
  {
    name: "Praveenreddy Busupally",
    text: "JSR construction maintains better quality construction. And has good and polite behaviour with the customers.",
  },
  {
    name: "NARESH KUMAR",
    text: "Good concrection, used Quality material, Very good company to purchase House's",
  },
  {
    name: "prasad sirigiri",
    text: "Hi Reddy,I would like to express my satisfaction with the construction process handled by Brick & Bolt, along with the exceptional efforts of their Project Management and Site Engineer (SE) teams. The entire experience has been smooth and well-organized, from the initial planning stages to the ongoing project execution.The Project Manager Obul Reddy garu have been highly professional, maintaining clear communication and ensuring that the project remains on schedule while adhering to high-quality standards. They have been proactive in addressing any concerns and have provided valuable suggestions throughout the process.I am especially impressed with their commitment to delivering quality workmanship and ensuring that all stages of construction meet the expectations set out at the beginning. The Brick & Bolt team has made the construction journey seamless, and I appreciate their attention to detail and the transparency maintained during the project. Overall, I highly recommend Brick & Bolt for their reliable project management and excellent construction execution.,Team lead :- Obul Reddy ,Site Project Manager :- Obul Reddy ,Contractor :- K Obul Reddy",
  },
  {
    name: "Mallikarjuna reddy Sangani",
    text: "Very good JSR constructions Nice 99%100",
  },
  {
    name: "BANDI KARTHIKREDDY",
    text: "If someone is searching for the best constructions for custom home building. Trust JSR CONSTRUCTIONS .Best quality work with affordable prices",
  },
];

const Home = () => {
  const [showContact, setShowContact] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [testimonials.length]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handleContactClick = () => {
    setShowContact(!showContact);
  };

  return (
    <div className="home-page">
      <Carousel3D images={images} logo={logoimg} />
      {/* Hero Section */}
      {/* <div className="hero-container">
        <div className="hero-content">
          <h1>Building Legacies for Over 20 Years</h1>
          <p>Transforming your vision into reality with our commitment to excellence.</p>
          <Link to="/contact" className="hero-btn">Get Started</Link>
        </div>
      </div> */}

      {/* About Us Section */}
      <div className="about-us">
        <h2>Why JSR Constructions?</h2>
        <p>
          At JSR Constructions, our success is driven by our unwavering commitment to the client's vision. Our approach combines a culture of balance, integrity, leadership, and loyalty. We believe in creating an environment that fosters collaboration and engagement, ensuring that every project meets the highest standards of safety, quality, practicality, and beauty.
        </p>
        <p>
          Our goal is not just to build structures but to create assets that stand the test of time. With over two decades of experience, we have completed more than 100 projects and served over 150 clients, building relationships that last as long as the buildings we construct.
        </p>
        <div className="stats">
          <div className="stat">
            <h3>
              <CountUp end={100} duration={5} suffix="+" />
            </h3>
            <p>Projects Completed</p>
          </div>
          <div className="stat">
            <h3>
              <CountUp end={20} duration={5} suffix=" Years" />
            </h3>
            <p>Experience in the Field</p>
          </div>
          <div className="stat">
            <h3>
              <CountUp end={150} duration={5} suffix="+" />
            </h3>
            <p>Clients Served</p>
          </div>
        </div>
      </div>

      {/* Core Values Section */}
      <div className="core-values">
        <h2>Our Core Values</h2>
        <div className="values-container">
          {CoreValues.map((value, index) => (
            <div key={index} className="value-card">
              <div className="image-container">
                <img src={value.image} alt={value.title} />
              </div>
              <h3>{value.title}</h3>
              <p>{value.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Client Collaboration Section */}
      <div className="client-collaboration">
        <h2>Collaborating for Success</h2>
        <p>
          Our collaborative approach involves working closely with a diverse range of stakeholders to achieve project success.
          From initial consultations to final delivery, we ensure that every aspect of the project is handled with expertise and care.
        </p>
        <div className="collaboration-flow">
          <div className="flow-step">
            <div className="step-icon">1</div>
            <h3>Step 1</h3>
            <p>Initial Consultation and Planning</p>
          </div>
          <div className="flow-step">
            <div className="step-icon">2</div>
            <h3>Step 2</h3>
            <p>Design and Development</p>
          </div>
          <div className="flow-step">
            <div className="step-icon">3</div>
            <h3>Step 3</h3>
            <p>Construction and Execution</p>
          </div>
          <div className="flow-step">
            <div className="step-icon">4</div>
            <h3>Step 4</h3>
            <p>Final Delivery and Review</p>
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="testimonials-slider1">
        <h1>What Our Clients Say</h1>
      </div>

      <div className="testimonials-slider">
        <div className="slider-container">
          <button className="arrow left-arrow" onClick={handlePrevious}>
            &#10094;
          </button>
          <div className="testimonial-slide">
            <div className="testimonial-content">
              <p className="testimonial-text">"{testimonials[currentIndex].text}"</p>
              <h3 className="testimonial-name">{testimonials[currentIndex].name}</h3>
              {/* If testimonials have positions, ensure they are included. Otherwise, remove the following line */}
              {/* <p className="testimonial-position">{testimonials[currentIndex].position}</p> */}
            </div>
          </div>
          <button className="arrow right-arrow" onClick={handleNext}>
            &#10095;
          </button>
        </div>
        <div className="news-section">
        <h2>Our Happy Clients</h2>
        <NewsScroller />
      </div>
        <div className="dots-container">
          {testimonials.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>

      {/* Contact Us Section */}
      {/* Uncomment and update if needed
      <div className={`contact-us ${showContact ? 'show' : ''}`} id="contact">
        <h2>Contact Us</h2>
        <p>We'd love to hear from you! Whether you have a question, want to discuss a project, or just want to say hello, feel free to reach out.</p>
        <form className="contact-form">
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea name="message" placeholder="Your Message" required></textarea>
          <button type="submit">Send Message</button>
        </form>
        <button className="contact-toggle" onClick={handleContactClick}>
          {showContact ? 'Hide Contact Form' : 'Show Contact Form'}
        </button>
      </div>
      */}
    </div>
  );
};

export default Home;
