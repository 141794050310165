// src/components/Carousel.js
import React, { useState, useEffect } from 'react';
import './Carousel3D.css'; // Updated CSS file

const Carousel = ({ images, logo, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Auto-slide effect using useEffect
  useEffect(() => {
    const slideInterval = setInterval(nextSlide, interval);

    return () => clearInterval(slideInterval); // Cleanup on unmount
  }, [currentIndex, interval]);

  return (
    <div className="custom-carousel">
      <div
        className="custom-carousel__inner"
        style={{ transform: `translateY(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <div className="custom-carousel__item" key={index}>
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </div>

      {/* Centered Logo with Light Black Background */}
      <div className="custom-carousel__logo-overlay">
        <img src={logo} alt="Logo" className="custom-carousel__logo" />
      </div>

      <button
        className="custom-carousel__control custom-carousel__control--prev"
        onClick={prevSlide}
        aria-label="Previous Slide"
      >
        &#10094;
      </button>
      <button
        className="custom-carousel__control custom-carousel__control--next"
        onClick={nextSlide}
        aria-label="Next Slide"
      >
        &#10095;
      </button>
    </div>
  );
};

export default Carousel;
