// Sidebar.js
import React from 'react';
import './Sidebar.css'; // Add your custom styles here

const Sidebar = ({ currentSection, setCurrentSection }) => {
  return (
    <div className="sidebar">
        <h2>JSR Constructions</h2>
      <h3>Admin Panel</h3>
      <ul>
        <li
          className={currentSection === 'home' ? 'active' : ''}
          onClick={() => setCurrentSection('home')}
        >
          Home
        </li>
        <li
          className={currentSection === 'add-property' ? 'active' : ''}
          onClick={() => setCurrentSection('add-property')}
        >
          Add New Property
        </li>
        <li
          className={currentSection === 'view-properties' ? 'active' : ''}
          onClick={() => setCurrentSection('view-properties')}
        >
          View Properties
        </li>
        <li
          className={currentSection === 'add-projects' ? 'active' : ''}
          onClick={() => setCurrentSection('add-projects')}
        >
         Add-projects
        </li>
        <li
          className={currentSection === 'view-projects' ? 'active' : ''}
          onClick={() => setCurrentSection('view-projects')}
        >
         View-projects
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
