import React from 'react';
import './Careers.css';
import car from '../images/join.jpg';

// Job Listings Data
const jobListings = [
  { 
    title: 'Project Manager', 
    description: 'Lead construction projects from start to finish.'
  },
  { 
    title: 'Site Engineer', 
    description: 'Manage and supervise on-site construction activities.'
  },
  { 
    title: 'Construction Foreman', 
    description: 'Oversee daily operations on construction sites.'
  },
  { 
    title: 'Architect', 
    description: 'Design and plan construction projects.'
  },
  { 
    title: 'Estimator', 
    description: 'Calculate project costs and prepare estimates.'
  },
  { 
    title: 'Safety Officer', 
    description: 'Ensure safety protocols are followed on site.'
  }
  // Add more job listings as needed
];

const Careers = () => {
  return (
    <div className="careers-wrapper">
      <section className="intro-section">
        <img className="intro-image" src={car} alt='Join Our Team' />
        <h1 className="title">Join Our Team at JSR Constructions</h1>
        <p className="paragraph">
          At JSR Constructions, we don’t just build structures—we build careers. For over 20 years, we’ve been at the forefront of innovative construction projects, known for our commitment to quality and our dynamic, supportive team. 
        </p>
        <p className="paragraph">
          If you’re driven, passionate about construction, and eager to be part of a company that values integrity, teamwork, and growth, explore our career opportunities and join us in shaping the future of construction excellence.
        </p>
      </section>

      <section className="join-section">
        <h2>Career Opportunities</h2>
        <div className="job-list">
          {jobListings.map((job, index) => (
            <div className="job-item" key={index}>
              <div className="job-details">
                <h2 className="job-title">{job.title}</h2>
                <p className="job-description">{job.description}</p>
                <a className="apply-button" href={`mailto:info@jsrconstructions09.com?subject=Application for ${job.title}`} target="_blank" rel="noopener noreferrer">
                  Enquire Now
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="submit-section">
        <h2>Ready to Build Your Future with Us?</h2>
        <a className="apply-button" href={`mailto:info@jsrconstructions09.com`} target="_blank" rel="noopener noreferrer">
          <p>Submit your resume and cover letter to info@jsrconstructions09.com</p>
        </a>
      </section>
    </div>
  );
};

export default Careers;
