import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import img from '../logo_wbg-removebg-preview.png';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header className="header">
      <div className="header-content">
        <div className="logo-container">
          <img src={img} alt="SR Constructions Logo" className="logo-image" />
          <div className="logo-text">
            <div className="company-name">JSR CONSTRUCTIONS</div>
            <div className="tagline">We Build Your Dream</div>
          </div>
        </div>
        <nav className={isOpen ? "nav open" : "nav"}>
          <ul>
            <li><Link to="/" onClick={closeMenu}>Home</Link></li>
            <li><Link to="/about-us" onClick={closeMenu}>About Us</Link></li>
            <li><Link to="/projects" onClick={closeMenu}>Projects</Link></li>
            <li><Link to="/services" onClick={closeMenu}>Services</Link></li>
           
            <li><Link to="/portfolio" onClick={closeMenu}>Catalog</Link></li>
            
            <li><Link to="/careers" onClick={closeMenu}>Careers</Link></li>
            <li><Link to="/contact" onClick={closeMenu}>Contact</Link></li>
            <li><Link to="/admin" onClick={closeMenu}>Login</Link></li>
            <div className="video-call-btn">
            <a href="https://wa.me/+916303034399" target="_blank" rel="noopener noreferrer">
              <button className="video-call-btn">
                <i className="fab fa-whatsapp"></i> WhatsApp
              </button>
            </a>
          </div>
            
          </ul>
        </nav>
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={isOpen ? "bar open" : "bar"}></div>
          <div className={isOpen ? "bar open" : "bar"}></div>
          <div className={isOpen ? "bar open" : "bar"}></div>
        </div>
        {/* Instant Video Call Button */}
       
      </div>
    </header>
  );
};

export default Header;
