// PortfolioPage.js
import React, { useEffect, useState } from 'react';
import PortfolioCard from './PortfolioCard';
import './PortfolioPage.css';

const PortfolioPage = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://backend.jsrconstructions09.com/properties')
      .then((response) => response.json())
      .then((data) => {
        setProperties(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="portfolio-page">
      <h1>Our Catalog</h1>
      <div className="portfolio-grid">
        {properties.map((property) => (
          <PortfolioCard key={property.id} property={property} />
        ))}
      </div>
    </div>
  );
};

export default PortfolioPage;
