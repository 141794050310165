import React, { useState } from 'react';
import './ProjectForm.css';

const ProjectForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        location: '',
        description: '',
        image: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleFileChange = (e) => {
        setFormData(prevState => ({ ...prevState, image: e.target.files[0] }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('location', formData.location);
        formDataToSend.append('description', formData.description);
        if (formData.image) {
            formDataToSend.append('image', formData.image);
        }

        fetch('https://backend.jsrconstructions09.com/projects', {
            method: 'POST',
            body: formDataToSend,
        })
            .then(response => response.json())
            .then(data => {
                alert('Project uploaded successfully!');
                setFormData({
                    name: '',
                    location: '',
                    description: '',
                    image: null,
                });
            })
            .catch(error => console.error('Error uploading project:', error));
    };

    return (
        <div className="project-form">
            <h1>Upload New Project</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name">Project Name:</label>
                    <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                </div>
                <div>
                    <label htmlFor="location">Location:</label>
                    <input type="text" id="location" name="location" value={formData.location} onChange={handleChange} required />
                </div>
                <div>
                    <label htmlFor="description">Description:</label>
                    <textarea id="description" name="description" value={formData.description} onChange={handleChange} required />
                </div>
                <div>
                    <label htmlFor="image">Image:</label>
                    <input type="file" id="image" name="image" accept="image/*" onChange={handleFileChange} />
                </div>
                <button type="submit">Upload Project</button>
            </form>
        </div>
    );
};

export default ProjectForm;
