import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Vision from './pages/Vision';
import Mission from './pages/Mission';
import AboutUs from './pages/AboutUs';
import Values from './pages/Values';
import PortfolioPage from './pages/PortfolioPage';
import Projects from './pages/Projects';
import Services from './pages/Services';
import Contact from './pages/ContactUs';
import AdminDashboard from './components/AdminDashboard';
import './styles.css';
import Careers from './pages/Careers';
import LoadingSpinner from './components/Loader';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a data fetch or other async operations
    setTimeout(() => setLoading(false), 3000); // Example: 2 seconds loading time
  }, []);
  return (
    <Router>
       {loading ? (
        <LoadingSpinner /> // Display the spinner while loading
      ) : (
        <>
         <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/vision" element={<Vision />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/values" element={<Values />} />
        <Route path="/projects" element={<Projects />} />
        
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/careers" element={<Careers />} />
      </Routes>
      <Footer />
      </>
      )}
    </Router>
  );
};

export default App;
