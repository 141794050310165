import React from 'react';
import '../styles.css';

const Mission = () => {
  return (
    <div className="page-container">
      <h1>Mission</h1>
      <p>Tag: To Build the legacy of excellence</p>
      <p>
        To provide high-quality craftsmanship and customer service while maintaining the utmost professionalism,
        transparency, and respect in our interactions with our customers, workers, and vendors.
        To expand into new sectors that build on our capabilities and consumer interests, we will be continuing
        to provide useful and substantial products, services, and solutions to the markets we now serve.
      </p>
    </div>
  );
};

export default Mission;
