import React, { useState } from 'react';
import AddPropertyForm from './AddPropertyForm';
import PropertyList from './PropertyList';
import Login from './Login';
import Sidebar from './Sidebar';
import './AdminDashboard.css'; // Ensure this file styles the dashboard appropriately
import ProjectForm from './ProjectForm';
import ProjectList from './ProjectList';

const AdminDashboard = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentSection, setCurrentSection] = useState('home');

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setCurrentSection('home'); // Reset to home section on logout
  };

  const renderSection = () => {
    switch (currentSection) {
      case 'add-property':
        return <AddPropertyForm />;
      case 'view-properties':
        return <PropertyList />;
      case 'add-projects':
        return <ProjectForm />;
      case 'view-projects':
        return <ProjectList />;

      default:
        return (
          <div className='back'>
            <h1>Welcome To JSR Constructions Admin Panel</h1>
          
          </div>
        );
    }
  };

  return (
    <div className="admin-dashboard">
      {!isLoggedIn ? (
        <Login onLogin={handleLogin} />
      ) : (
        <div className="dashboard-wrapper">
          <Sidebar currentSection={currentSection} setCurrentSection={setCurrentSection} />
          <div className="dashboard-content">
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
           
            {renderSection()}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
