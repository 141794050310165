import React from 'react';
import './NewsScroller.css'; // Import CSS for styling
import ann from '../happy_clients/IMG-20241023-WA0001.jpg';
import ann2 from '../happy_clients/IMG-20241023-WA0002.jpg';
import ann3 from '../happy_clients/IMG-20241023-WA0003.jpg';
import ann4 from '../happy_clients/IMG-20241023-WA0004.jpg';
import ann5 from '../happy_clients/IMG-20241023-WA0005.jpg';
import ann6 from '../happy_clients/IMG-20241023-WA0006.jpg';
import ann7 from '../happy_clients/IMG-20241023-WA0007.jpg';
import ann8 from '../happy_clients/IMG-20241023-WA0008.jpg';
import ann9 from '../happy_clients/IMG-20241023-WA0009.jpg';
import ann10 from '../happy_clients/IMG-20241023-WA0010.jpg';
import ann11 from '../happy_clients/IMG-20241023-WA0011.jpg';
import ann12 from '../happy_clients/IMG-20241023-WA0012.jpg';
import ann13 from '../happy_clients/IMG-20241023-WA0013.jpg';
import ann14 from '../happy_clients/IMG-20241023-WA0014.jpg';
import ann15 from '../happy_clients/IMG-20241023-WA0015.jpg';
import ann16 from '../happy_clients/IMG-20241023-WA0016.jpg';

const newsItems = [
  {
    id: 1,
    image: ann,
    
    
  },
  {
    id: 2,
    image: ann2,
    title: 'Latest News 2',
    
  },
  {
    id: 3,
    image: ann3,
    title: 'Latest News 3',
  
  },
  {
    id: 4,
    image:ann4,
    title: 'Latest News 4',
   
  },
  {
    id: 5,
    image:ann5,
    title: 'Latest News 4',
   
  },
  {
    id: 6,
    image:ann6,
    title: 'Latest News 4',
   
  },
  {
    id: 7,
    image:ann7,
    title: 'Latest News 4',
   
  },
  {
    id: 8,
    image:ann8,
    title: 'Latest News 4',
   
  },
  {
    id: 9,
    image:ann9,
    title: 'Latest News 4',
   
  },
  {
    id: 10,
    image:ann10,
    title: 'Latest News 4',
   
  },
  {
    id: 11,
    image:ann11,
    title: 'Latest News 4',
   
  },
  {
    id: 12,
    image:ann12,
    title: 'Latest News 4',
   
  },
  {
    id: 13,
    image:ann13,
    title: 'Latest News 4',
   
  },
  {
    id: 14,
    image:ann14,
    title: 'Latest News 4',
   
  },
  {
    id: 15,
    image:ann15,
    title: 'Latest News 4',
   
  },
  {
    id: 16,
    image:ann16,
    title: 'Latest News 4',
   
  },
];

const NewsScroller = () => {
  return (
    <div className="news-scroller-wrapper">
      <div className="news-scroller">
        {newsItems.map((item, index) => (
          <div key={index} className="news-item">
            <img src={item.image} alt={item.title} />
            <div className="news-content">
             
             
            </div>
          </div>
        ))}
        {newsItems.map((item, index) => (
          <div key={index + newsItems.length} className="news-item">
            <img src={item.image} alt={item.title} />
            <div className="news-content">
          
              
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsScroller;
