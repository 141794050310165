import React from 'react';
import './services.css'; // Import your CSS file for styling

const ServicesPage = () => {
  const services = [
    {
      title: 'House Construction',
      description: `Our house construction services are designed to provide you with a home that reflects your unique style and meets your functional needs. With a commitment to top-quality craftsmanship and attention to detail, we ensure that every aspect of your house, from the foundation to the finishing touches, is built to last. We use the latest construction techniques and high-quality materials to create homes that are not only durable but also elegant and energy-efficient. Our team works closely with you to understand your vision and deliver a finished product that exceeds your expectations.`,
      model: 'https://sketchfab.com/models/c2070d21f4e749b9806adbbd68b637d7/embed?autospin=1&autostart=1&preload=1',
    },
    {
      title: 'Real Estate & Development',
      description: `Our real estate and development services offer a comprehensive approach to property development. Whether you're looking to invest in new properties, develop commercial spaces, or explore residential opportunities, we provide expert guidance throughout the entire process. Our team handles everything from initial planning and site selection to project management and construction. We leverage our industry knowledge and network to ensure that your investment yields the best possible return, while also adhering to all regulatory requirements and standards.`,
      model: 'https://sketchfab.com/models/7685d05e420e42f0889339f292778100/embed?autospin=1&preload=1',
      
    },
    {
      title: 'Remodelling Works',
      description: `Transform your existing space with our professional remodelling services. We specialize in enhancing the functionality and aesthetics of your home or commercial property through thoughtful design and expert execution. Whether you're updating a single room or undertaking a complete renovation, our team provides personalized solutions to meet your needs and budget. We work with you to reimagine your space, incorporating modern design trends and innovative features to create a refreshed environment that adds value and comfort.`,
      model: 'https://sketchfab.com/models/8a6362b955654d5c93125d20ad46d535/embed?autospin=1&preload=1',
    },
    {
      title: 'Design & Planning',
      description: `Our design and planning services are aimed at bringing your vision to life with precision and creativity. From conceptual design to detailed planning, our team works closely with you to develop a plan that meets your goals and adheres to your budget. We handle all aspects of design, including architectural plans, interior design, and landscape architecture. Our approach is collaborative, ensuring that every detail is considered and that the final design reflects your personal style and functional needs.`,
      model: 'https://sketchfab.com/models/8b4197c57f214cd9b069165ff2bb4bb3/embed?autospin=1&preload=1',
    },
    {
      title: 'Estimations',
      description: `Accurate and transparent estimations are crucial for successful project planning and execution. Our estimation services provide you with a detailed breakdown of costs, helping you make informed decisions and manage your budget effectively. We evaluate all aspects of the project, including materials, labor, and overheads, to provide a comprehensive estimate that covers all potential expenses. Our goal is to ensure that there are no surprises and that you have a clear understanding of the financial aspects of your project from the start.`,
      model: 'https://sketchfab.com/models/3c40efe186b34547ad8b6847b5dea989/embed?autospin=1&preload=1',
    },
    // Add more services here
  ];

  return (
    <div className="services-page">
      {services.map((service, index) => (
        <div key={index} className="service-section">
          <div className="service-text">
            <h2>{service.title}</h2>
            <p>{service.description}</p>
          </div>
          <div className="service-3d">
            <iframe 
              title={service.title}
              frameBorder="0"
              allowFullScreen
              src={service.model}
              style={{ width: '100%', height: '400px' }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServicesPage;
