import React from 'react';
import './Footer.css';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section contact-info">
          <h3>Contact Us</h3>
          <p><FaPhoneAlt className="icon" /> +91 6303034399</p>
          <p><FaEnvelope className="icon" /> <a href="mailto:info@jsrconstructions.com">info@jsrconstructions09.com</a></p>
          <p><FaMapMarkerAlt className="icon" />Jsr constructions, Satyadev Enclave, Sriramana Colony, Hastinapuram, Hyderabad, Telangana 500079</p>
        </div>

        <div className="footer-section ">
          <h3>Our Services</h3>
          <ul>
            <li>House Construction</li>
            <li>Real Estate & Development</li>
            <li>Remodelling Works</li>
            <li>Design & Planning</li>
            <li>Estimations</li>
          </ul>
        </div>

        <div className="footer-section about">
          <h3>About JSR Constructions</h3>
          <p>
            JSR Construction has been the appropriate building partner for the region's most forward-thinking enterprises for over 2 decades. Our success stems from our unwavering dedication to the client's vision, as well as a culture of balance, integrity, leadership, and loyalty.
          </p>
        </div>

        <div className="footer-section social-media">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Copyright © 2024 JSR Constructions. All Rights Reserved.</p>
        <p>Built with <span role="img" aria-label="heart">🤍</span> by <a href="https://elevatesphere.tech">elevatesphere.tech</a></p>
      </div>
    </footer>
  );
};

export default Footer;
