import React from 'react';
import './AboutUsPage.css';
import { FaClock, FaLightbulb, FaAward, FaCheckCircle, FaHeadset, FaClipboardCheck } from 'react-icons/fa';

const AboutUs = () => {
  return (
    <div className="about-us-page">
      {/* Section 1: Introduction */}
      <div className="section">
        <div className="text-left">
          <h2>About JSR Constructions</h2>
          <p>
            At JSR Constructions, we pride ourselves on being innovators in the construction and interior design industry, delivering tailored solutions to meet the unique needs of our clients. Our commitment to visionary design and uncompromising quality has earned us a reputation for excellence, creating lasting impressions wherever we go. With a focus on simplicity, originality, and attention to detail, we transform spaces that not only meet but exceed expectations.
          </p>
          <p>
            Our journey spans over a decade of creative brilliance and technical mastery. From corporate projects to residential developments, we have consistently pushed the boundaries of what is possible, delivering exceptional results that speak to our expertise, dedication, and passion. The hallmark of JSR Constructions lies in our relentless pursuit of innovation, combined with the precision and perseverance that ensures every project is a success.
          </p>
        </div>
        <div className="image-right">
          <img src={require('../images/aboutimg.png')} alt="JSR Constructions Project" />
        </div>
      </div>

      {/* Section 2: Strengths */}
      <div className="section">
        <div className="image-left">
          <img src={require('../images/about1.png')} alt="JSR Strengths" />
        </div>
        <div className="text-right">
          <h2>Our Strengths</h2>
          <p>
            Armed with cutting-edge tools and an unwavering commitment to quality, JSR Constructions stands head and shoulders above the competition. Our expert team of engineers and technicians consistently deliver projects with precision, ensuring that every detail is executed to perfection. Our clients appreciate not just our design sensibilities, but the seamless and efficient execution that turns ideas into reality.
          </p>
          <p>
            Whether it's construction or interior contracting, we bring unparalleled expertise and a quality-first approach to every project. We rigorously adhere to industry standards, ensuring that the highest levels of craftsmanship are maintained, regardless of project size or scope.
          </p>
        </div>
      </div>

      {/* Section 3: Services */}
      <div className="section">
        <div className="text-left">
          <h2>Our Services</h2>
          <p>
            At JSR Constructions, our mission is to add value at every stage of the process. We don’t just build; we partner with our clients to bring their vision to life, offering expert guidance and bespoke solutions tailored to their needs. From concept to completion, we are committed to creating long-term relationships based on trust, quality, and excellence.
          </p>
          <p>
            We offer only the highest-quality, certified products and have been recognized for our outstanding contributions to the industry. Our award-winning designs and construction services ensure that every project we undertake is completed to the highest standards.
          </p>
        </div>
        <div className="image-right">
          <img src={require('../images/about3.png')} alt="JSR Services" />
        </div>
      </div>

      {/* Section 4: Why Choose Us */}
      <div className="section">
        <div className="image-left">
          <img src={require('../images/about4.png')} alt="Why Choose JSR" />
        </div>
        <div className="text-right">
          <h2>Why Choose JSR Constructions?</h2>
          <ul className="interactive-list">
            <li>
              <FaClock className="icon" />
              <strong>On-Time Delivery:</strong> We prioritize timely project completion without compromising on quality.
            </li>
            <li>
              <FaLightbulb className="icon" />
              <strong>Innovative Designers:</strong> Our creative team designs with precision, balancing your style, space, and budget perfectly.
            </li>
            <li>
              <FaCheckCircle className="icon" />
              <strong>Premium Quality:</strong> We deliver only the finest, certified materials for all of our projects.
            </li>
            <li>
              <FaClipboardCheck className="icon" />
              <strong>Complimentary Consultations:</strong> We offer expert consultation to ensure your project meets all your expectations.
            </li>
            <li>
              <FaHeadset className="icon" />
              <strong>24/7 Support:</strong> Our dedicated team is always available to assist, providing top-notch support around the clock.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
